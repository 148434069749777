
import { createApp } from 'vue';
import App from './App';
import router from './router';
import { store } from './store/store'
import { Amplify } from 'aws-amplify';
import ENV from '@/config';
import { createPlausible } from 'v-plausible/vue'
import '@/css/global.scss'
import { Synthetics } from 'aws-sdk';
import VueGtag from "vue-gtag";
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

const application = createApp(App)

Amplify.configure({
    Auth: {
        // REQUIRED - Amazon Cognito Identity Pool ID
        identityPoolId: ENV.identityPoolId,
        // REQUIRED - Amazon Cognito Region
        region: ENV.region,
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: ENV.userPoolId,
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: ENV.userPoolWebClientId,
        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: ENV.mandatorySignIn,
        // // OPTIONAL - Configuration for cookie storage
        //     cookieStorage: {
        //     // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        //         domain: '.yourdomain.com',
        //     // OPTIONAL - Cookie path
        //         path: '/',
        //     // OPTIONAL - Cookie expiration in days
        //         expires: 365,
        //     // OPTIONAL - Cookie secure flag
        //         secure: true
        //     }
        oauth: {
            domain: ENV.oAuthDomain,
            // Authorized scopes
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            // Callback URL
            redirectSignIn: ENV.oAuthAfterSignIn, // or 'exp://127.0.0.1:19000/--/', 'myapp://main/'
            // Sign out URL
            redirectSignOut: ENV.oAuthAfterSignOut, // or 'exp://127.0.0.1:19000/--/', 'myapp://main/'
            // 'code' for Authorization code grant, 
            // 'token' for Implicit grant
            // Note that REFRESH token will only be generated when the responseType is code
            responseType: 'token',
            // optional, for Cognito hosted ui specified options
            options: {
                // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
                AdvancedSecurityDataCollectionFlag: true
            },

            // urlOpener: urlOpener
        }
    },
    Storage: {
        bucket: ENV.bucket, //REQUIRED -  Amazon S3 bucket
        region: ENV.region, //OPTIONAL -  Amazon service region
    },
    API: {
        endpoints: [{
                name: "LAMBDA_CREATE_USER",
                endpoint: `https://lambda.${ENV.region}.amazonaws.com/2015-03-31/functions/msu-admin-create-user/invocations`,
                service: "lambda",
                region: ENV.region
            },
            {
                name: "LAMBDA_PARSE_CSV",
                endpoint: `https://lambda.${ENV.region}.amazonaws.com/2015-03-31/functions/msu-parse-csv/invocations`,
                service: "lambda",
                region: ENV.region
            },
            {
                name: "MSU_ADD_TO_CM",
                endpoint: `https://lambda.${ENV.region}.amazonaws.com/2015-03-31/functions/msu-add-to-cm/invocations`,
                service: "lambda",
                region: ENV.region
            }
        ]
    }
});

const plausible = createPlausible({
    init: {
      domain: 'moneysanityu.com',
      trackLocalhost: false,
    },
    settings: {
      enableAutoOutboundTracking: true,
      enableAutoPageviews: true,
    },
  })

application
    .use(router)
    .use(store)
    .use(plausible)
    .use(VueGtag, {
        appName: 'MoneySanityU',
        pageTrackerScreenviewEnabled: true,
        config: { id: "UA-134511684-1" }
    }, router)
    .use(FloatingVue)
    .mount('#app')