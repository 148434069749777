<template>
      <div class="userDetailsModalInner" >
        <div class="loading" v-if="!member || !memberAttributes">
          Loading...
        </div>
        <div class="memberInfo" v-if="member && memberAttributes">
          <div class="userPic" :class="memberAttributes['custom:role'] == 'manager' ? 'manager':''"></div>
          <div class="basicInfo">
            <div v-if="!memberAttributes.given_name || memberAttributes.given_name == 'not_given' || !memberAttributes.family_name || memberAttributes.family_name == 'not_given'">
              <h3>{{memberAttributes.email}}</h3>
            </div>
            <div v-else>
              <h3>{{memberAttributes.given_name}} {{memberAttributes.family_name}}</h3>
              <h5>{{memberAttributes.email}}</h5>
            </div>
          </div>
          <div class="moreInfo">
            <h4>Invited: <span>{{invitedDate}}</span></h4>
            <h4>Account Status: <span v-if="member.UserStatus === 'FORCE_CHANGE_PASSWORD'">PENDING</span><span v-else>{{member.UserStatus}}</span></h4>
            <h4 v-if="memberProgress && member.UserStatus !== 'FORCE_CHANGE_PASSWORD'">Completed Units: <span>{{memberProgress.activeUnits?.length}}</span></h4>
          </div>

          <!-- <h3>{{memberAttributes["custom:role"]}} of group: {{memberAttributes["custom:groupSlug"]}}</h3>
          <h3>Enabled: {{member.Enabled}}</h3> -->

          <div class="resendInvite" v-if="member.UserStatus === 'FORCE_CHANGE_PASSWORD'">
            <h5 :class="timeClass">Waiting for user to activate account. (<span>{{invitedTime}} Days</span>)</h5>
            <div><button @click="resendInvite(memberAttributes.email)">Resend Invite</button></div>
            <label data-on="ON" data-off="OFF" class="toggle" v-if="user.attributes['custom:role']=='admin'">
              Trigger new welcome email on reset?
              <div class="switch">
                <span class="subText">No</span>
                <input type="checkbox" id="checkbox" v-model="sendEmailOnReset">
                <span class="track">
                  <span class="center"></span>
                </span>
                <span class="subText">Yes</span>
              </div>
            </label>
          </div>
        </div>

      </div>

</template>

<script>
import AmplifyStore from '@/store/store'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default {
  name: 'Member',
  props: ['sub'],
  data () {
    return {
      member: null,
      memberAttributes: null,
      memberProgress: null,
      timeClass: 'good',
      sendEmailOnReset: true,
    }
  },
  mounted() {
    if(this.user.attributes['custom:role'] === 'manager') {
      AmplifyStore.dispatch('admin/getGroupBySlug', this.user.attributes['custom:groupSlug'])
        .then( () => {
          this.checkGroup()
        })
    }

    if(this.user.attributes['custom:role'] === 'admin') {
      this.getUser()
    }
  },
  created () {
  },
  unmounted () {
    this.member = null
    this.memberProgress = null
  },
  methods: {
    setUserAttributes: function(userAttributes) {
      let attr = {}

      for(let i = 0; i < userAttributes.length; i++){
        let key = userAttributes[i].Name
        let value = userAttributes[i].Value
        attr[key] = value
      }

      this.memberAttributes = attr
    },
    checkGroup: function() {
      let userInGroup = this.group.members.find(m => {
        return m.sub === this.sub
      })

      if(this.user.attributes['custom:role'] === 'manager' && !userInGroup) {
        AmplifyStore.commit('set_flashMessage', {
          show: true,
          level: 'error',
          message: 'Permission denied. User is not a member of your group'
        })
        return
      }

      this.getUser()
    },
    getUser: async function() {
      let that = this
      let params = {
        TableName: `${this.ENV.tablePrefix}Users`,
        Key: { "user": this.sub }
      }
      let user = new Promise((resolve, reject) => {
        this.cognito.adminGetUser({UserPoolId: this.user.pool.userPoolId, Username: this.sub }, function(err, data) {
          if (err)  {that.member = null; reject();} // an error occurred
          else     {that.member = data; resolve(data);}       // successful response
        });
      })

      this.docClient.get(params, function(err, data) {
        if(err) that.memberProgress = null;
        else that.memberProgress = data.Item;
      })

      await user.then( user => {
        this.setUserAttributes(user.UserAttributes)
      })
    },
    resendInvite: function(userEmailAddress) {
      const cleanEmail = userEmailAddress.toLowerCase()
      let sendEmail = true
      if(!this.sendEmailOnReset){
        sendEmail = false
      }
      let params = {
        username: cleanEmail,
        notification: sendEmail
      }
      AmplifyStore.dispatch('admin/resendInvite', params)
        .then(() => {
          let res = "Invitation email has been resent to " + params.username
          if(!params.notification){
            res = params.username + " account reset, no email sent."
          }
          AmplifyStore.commit('set_flashMessage', {
            show: true,
            level: 'success',
            message: res
          })
        })
    },
     
    
  },
  computed: {
    ENV() { return AmplifyStore.state.ENV },
    user() { return AmplifyStore.state.user },
    group() { return AmplifyStore.state.admin.allGroups.Item },
    docClient() { return AmplifyStore.state.docClient },
    cognito() { return AmplifyStore.state.cognito },
    invitedDate: function(){
      return dayjs(this.member.UserCreateDate).format("MMMM D, YYYY")
    },
    invitedTime: function(){
      let createdDate = this.member.UserCreateDate
      let modDate = this.member.UserLastModifiedDate
      let timeSince =parseInt(dayjs(createdDate).diff(dayjs(), 'day') * -1)
      if(createdDate != modDate){
        timeSince = parseInt(dayjs(modDate).diff(dayjs(), 'day') * -1)
      }
      
      if(timeSince > 45){
        this.timeClass = "warn"
      } 
      if (timeSince > 70){
        this.timeClass = "alert"
      }
      return timeSince
    },

  }
}
</script>
